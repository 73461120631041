<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Администраторы</div>
                <div class="page__desc">Список с амдинистраторами</div>
            </div>
        </div>

        <div v-if="users != null"
             class="page__content">

            <paginate
                    :page-count="users.pageLast"
                    :click-handler="paginator"
                    v-model="page"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                    :prev-class="'page-item'"
                    :next-class="'page-item'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"/>

            <div class="table-responsive">
                <table class="table table-striped">
                    <tr>
                        <th>ID</th>
                        <th>Email</th>
                        <th>Роль</th>
                        <th>Дата регистрации</th>
                    </tr>
                    <tbody v-if="!this.$store.state.loading.showLoading">
                    <tr v-for="user in users.items" v-bind:key="users.id">
                        <td>
                            <router-link
                            :to="{ name: 'AdminsUpdate', params: {id: user.id } }">#{{ user.id }}</router-link>
                        </td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.role }}</td>
                        <td>{{ user.created_at | moment("DD MMM YYYY HH:mm") }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import paginate from 'vuejs-paginate'
    import router from '@/router'

    export default {
        name: 'AdminsIndex',
        components: {
            paginate,
        },
        data() {
            return {
                page: this.$route.query.page != null ? parseInt(this.$route.query.page) : 1
            }
        },
        computed: {
            users() {
                return this.$store.state.admins.admins
            }
        },
        methods: {
            ...mapActions([
                'adminsIndex'
            ]),
            async getUsers() {
                await this.adminsIndex({
                    page: this.page,
                    limit: 10
                })
            },
            paginator(pageNum) {
                router.push({ name: 'AdminsIndex', query: { page: pageNum }});
            }
        },
        created() {
            this.getUsers();
        },
        watch: {
            $route() {
                this.getUsers();
            }
        },
    }
</script>

<style lang="scss" scoped>
    .btn {
        margin-right: 10px;
    }
</style>